<template>
  <div>
    <head-layout
      :head-title="$t('cip.dc.KFDComission.kftitle')"
      :head-btn-options="headBtnOptions"
      @head-add="addFn"
    >
      <!-- @head-romve="deleteFn" -->
    </head-layout>

    <div class="headers" style="display: flex">
      <el-input
        :placeholder="$t('cip.dc.KFDComission.name')"
        v-model="queryList.serverName"
        class="new-sino-input"
        size="mini"
        style="width: 200px"
      />
      <el-button-group style="margin-left: 10px">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="onLoad"
        ></el-button>
        <el-button
          size="mini"
          icon="reset-refresh icon-refresh"
          @click="czFn"
        ></el-button>
      </el-button-group>
    </div>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      @grid-edit="handleEdit"
      @grid-romve="delRow"
    >
    </grid-layout>


  </div>
</template>

<script>


import {pageKafkaDataCollect,delectKafkaData }from "@/api/dataAcquisition/kafKa";

import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from "@/views/components/layout/form-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  data() {
    return {
      Executeloading: false,
      ExecuteTime: [], //执行时间

      dialogtext: "",
      runState: "",
      btnType: "",
      dialogVisible: false,
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        },

      ],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.dc.KFDComission.name'),
            prop: "serverName",
            cell: true,
          },
          {
            label: "集群地址",
            prop: "brokerList",
            cell: true,

          },
          {
            label: "topic配置",
            prop: "topic",
            cell: true,

            width: 200,
          },
          {
            label: "写入数据源id",
            prop: "writeDatasourceId",
            cell: true,
          },
          {
            label: "写入表",
            prop: "writeTable",
            cell: true,
          },

          {
            label: "状态",
            prop: "status",
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl:'/api/sinoma-system/dict-biz/dictionary?code=datacenter_kafka_col_status'
            // dicData: [{
            //   label: '暂停中',
            //   value: 0
            // },{
            //   label: '启动中',
            //   value: 1
            // }, {
            //   label: '已启动',
            //   value: 2
            // },
            //   {
            //     label: '已暂停',
            //     value: 3
            //   }],

          },
        ],
      },
      page: {
        size: PAGE_CONSTANT.TEN,
        current: 1,
        total: 0,
        // pageSizes:[5,10,15]
      },
      tableLoading: false,
      multipleSelection: [],
      tableData: [],
      total: 0,
      queryList: {
        size:10,
        current: 1,
        serverName: "",
      },
      loading: false,
      headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],
    };
  },
  computed: {},
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      pageKafkaDataCollect({...this.queryList}).then((res) => {
        this.loading = false;

        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    addFn() {
      this.$router.push({
        path: "/dataAcquisition/kafKaAdd",
        query: {
          type: "add",
        },
      });
    },
    handleEdit(item) {
      this.$router.push({
        path: "/dataAcquisition/kafKaAdd",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    delRow(e) {

      this.$confirm("确定删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          delectKafkaData(e.id).then((res) => {
            this.$message.success("删除成功");
            this.onLoad();
          });
        })
        .catch(() => {});
    },






    czFn() {
      this.queryList.serverName = "";
      this.onLoad();
    },
    handleSelectionChange(val) {

      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.queryList.current = 1;
      this.queryList.size=val.pageSize
      this.onLoad();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage;
      this.queryList.current=val.currentPage
      this.onLoad();
    },
  },
  created() {
    this.onLoad();
  },
  mounted() {},
};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}
.headers ::v-deep .el-button {
  height: 28px;
}
::v-deep .el-button + .el-button {
  margin-left: 0px;
}

</style>


